// import './theme-white.sass'
// import './theme-dark-colors.sass'
import './App.sass';

import { ReactComponent as LinkedInIcon } from './icons8-linkedin.svg';

import { Section, SectionRow, SectionRowList, SectionRowListLabel, SectionRowItem } from './Section.js';

function App() {
  return (
    <div className="App theme-white">
      <aside>
        <div className="profile"></div>
          <SectionRowList>
            <SectionRowItem><span id="my-name">Jan Skutil</span></SectionRowItem>
            <SectionRowItem><a href="mailto:jan.skutil@gmail.com">jan.skutil@gmail.com</a></SectionRowItem>
            <SectionRowItem><a href="tel:+420724925771">+420 724 925 771</a></SectionRowItem>
            <SectionRowItem className="exclude-from-print">
              <a href="https://www.linkedin.com/in/htaccess/" target="_blank" rel="noreferrer">
                <LinkedInIcon className="linkedin-icon" />
                LinkedIn profil
              </a>
            </SectionRowItem>
            <SectionRowItem className="exclude-from-print"><a href="javascript:if(window.print)window.print()">verze pro tisk</a></SectionRowItem>
          </SectionRowList>
      </aside>
      <div className="sections">
        <Section label="Vzdělání">
          <SectionRow>
            <SectionRowListLabel>SPŠE Úžlabina <span className="label-date-range">(2011{'\u00A0'}-{'\u00A0'}2015)</span></SectionRowListLabel>
            <SectionRowList>
              <SectionRowItem>obor 18-20-M/01 Informační technologie</SectionRowItem>
              <SectionRowItem>dlouhodobá maturitní práce - webový systém pro správu a plánování školního ECDL testování postavené na vlastním MVC PHP frameworku</SectionRowItem>
            </SectionRowList>
          </SectionRow>
          <SectionRow>
            <SectionRowListLabel>České vysoké učení technické - Fakulta informačních technologií <span className="label-date-range">(2015{'\u00A0'}-{'\u00A0'}2017)</span></SectionRowListLabel>
          </SectionRow>
          <SectionRow>
            <SectionRowListLabel>Technická univerzita v Liberci - Fakulta mechatroniky, informatiky a mezioborových studií <span className="label-date-range">(2017{'\u00A0'}-{'\u00A0'}2018)</span></SectionRowListLabel>
          </SectionRow>
        </Section>
        <Section label="Praxe">
          <SectionRow>
            <SectionRowListLabel>NetSprint s.r.o. + Pe3ny Net s.r.o. <span className="label-date-range">(2015 - 2024)</span></SectionRowListLabel>
            <SectionRowList>
              <SectionRowItem>správa sítě, komunikace se zákazníky, instalace a servis síťových zařízení</SectionRowItem>
              <SectionRowItem>výstavba vysílacích bodů a výstavba optické infrastruktury</SectionRowItem>
              <SectionRowItem>správa serverů, virtualizace v KVM, řešení dohledu sítě v prostředí Nagios/Icinga</SectionRowItem>
              <SectionRowItem>programování skriptů pro automatické řízení a dohled sítě</SectionRowItem>
              <SectionRowItem>vývoj webové aplikace (React Router) a backendu (FastApi) pro interní systém</SectionRowItem>
            </SectionRowList>
          </SectionRow>
        </Section>
        <div className="pagebreak" />
        <Section label="Zkušenosti">
          <SectionRow>
            <SectionRowListLabel>správa sítě</SectionRowListLabel>
            <SectionRowList>
              <SectionRowItem>konfigurace běžných L2 a L3 služeb (STP, OSPF, VLAN, ...), zkušenosti s instalací a provozem bezdrátové a optické sítě</SectionRowItem>
              <SectionRowItem>konfigurace kompletního firewallu na IPv4/IPv6 pro interní přístupovou síť, řešení detekce a report DDoS útoků</SectionRowItem>
              <SectionRowItem>práce s HW CISCO, Huawei, Ubiquiti, Mikrotik, ZTE</SectionRowItem>
              <SectionRowItem>návrh a stavba a nastavení WiFi sítě pro restaurace</SectionRowItem>
            </SectionRowList>
          </SectionRow>
          <SectionRow>
            <SectionRowListLabel>programování, skriptování, databáze</SectionRowListLabel>
            <SectionRowList>
              <SectionRowItem>Python 3, React JS, Angular, PHP, SQL (backend převážně MySQL)</SectionRowItem>
              <SectionRowItem>kódování webových stránek podle grafické předlohy a zprovoznění webhostingu na svém webhostingu</SectionRowItem>
              <SectionRowItem>kodování stylu v preprocesoru SASS</SectionRowItem>
            </SectionRowList>
          </SectionRow>
          <SectionRow>
            <SectionRowListLabel>správa serverů</SectionRowListLabel>
            <SectionRowList>
              <SectionRowItem>instalace a správa KVM</SectionRowItem>
              <SectionRowItem>skriptování v bash, instalace aplikací ze zdroje, základní zkušenosti s Dockerem</SectionRowItem>
              <SectionRowItem>administrace VPS - základní nastavení, údržba, řešení problému při zaplnění disku nebo selhání HW, nastavení zálohování</SectionRowItem>
            </SectionRowList>
          </SectionRow>
        </Section>
        <Section label="Zájmy">
          <SectionRow>
            <SectionRowListLabel>programování, web development</SectionRowListLabel>
            <SectionRowList>
              <SectionRowItem>rád se učím nové technologie a snažím se sledovat nejnovější vývoj v oblasti webdevu a ISP odvětví</SectionRowItem>
              <SectionRowItem>pro hackerspace TAKT Prague jsem vytvořil barový systém, pro který jsem se naučil programování v jazyku Angular</SectionRowItem>
              <SectionRowItem>ve volném čase jsem vytvořil webovou aplikaci pro správu skiptů pro přízení sítě v Pe3ny Net</SectionRowItem>
            </SectionRowList>
          </SectionRow>
        </Section>
      </div>
    </div>
  );
}

export default App;
